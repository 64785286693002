import ConfirmEmailContainer from '../confirmEmailModal/ConfirmEmailContainer';
import CookieConsentContainer from '../cookieConsentModal/CookieConsentContainer';
import ToastContainer from '../generic/Toast/ToastContainer';
import LoggedOutModal from '../login/LoggedOutModal';
import LoginNeededModal from '../login/LoginNeededModal';
import ProductReviewModal from '../product-tools/ProductReviewModal';

export default function Callables() {
    return (
        <>
            <LoggedOutModal.Root />
            <ToastContainer />
            <LoginNeededModal.Root />
            <ProductReviewModal.Root />
            <CookieConsentContainer />
            <ConfirmEmailContainer />
        </>
    );
}
