import { useCallback, useContext, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { rateProduct, UserData } from '../api/product';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import SpinnerContainer from '../generic/SpinnerContainer';
import { TrackingContext } from '../generic/TrackingContext';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import useStateWithSideEffect from '../utils/useStateWithSideEffect';

interface Props {
    productCode: string;
    rating: number | undefined;
    onChange?(userData: UserData): void;
}

export default function ReviewStars({ productCode, rating: initialRating, onChange }: Props) {
    const { item_list_id, item_list_name } = useContext(TrackingContext);
    const [isLoading, setLoading] = useBooleanState();
    const [error, setError] = useState<ErrorBoxType | null>(null);

    const trackRating = useCallback(
        (stars: number) => {
            gtag('event', 'rating', {
                stars,
                item_id: productCode,
                item_list_id,
                item_list_name,
            });
        },
        [productCode, item_list_id, item_list_name],
    );

    const saveRating = useDebounceCallback(
        useCallback(
            (rating: number) => {
                setLoading.toTrue();
                setError(null);
                trackRating(rating);
                rateProduct(productCode, { rating }).then(onChange).catch(setError).finally(setLoading.toFalse);
            },
            [trackRating, productCode],
        ),
        500,
    );
    const [rating, setRating] = useStateWithSideEffect(initialRating ?? 0, saveRating);

    const onClick = useCallback(
        (newRating: number) => {
            if (!onChange) return;

            if (newRating === rating) {
                setRating(0);
            } else {
                setRating(newRating);
            }
        },
        [onChange, rating],
    );

    return (
        <SpinnerContainer isSpinning={isLoading}>
            <ErrorBox errors={error} />
            <div className="product-item__rating-stars" role="radiogroup" aria-label="Gi produkt opp til 5 stjerner.">
                <Star stars={5} productCode={productCode} rating={rating} onClick={onClick} readonly={!onChange} />
                <Star stars={4} productCode={productCode} rating={rating} onClick={onClick} readonly={!onChange} />
                <Star stars={3} productCode={productCode} rating={rating} onClick={onClick} readonly={!onChange} />
                <Star stars={2} productCode={productCode} rating={rating} onClick={onClick} readonly={!onChange} />
                <Star stars={1} productCode={productCode} rating={rating} onClick={onClick} readonly={!onChange} />
            </div>
        </SpinnerContainer>
    );
}

function Star({
    stars,
    productCode,
    rating,
    onClick,
    readonly,
}: {
    stars: number;
    productCode: string;
    rating: number;
    onClick: (rating: number) => void;
    readonly: boolean;
}) {
    return (
        <>
            <input
                tabIndex={0}
                type="radio"
                id={`${productCode}_star${stars}`}
                aria-label={`${stars} stjerner`}
                name="rating"
                value={stars}
                checked={stars == rating}
                onClick={() => onClick(stars)}
                onChange={() => preventDefault()}
                disabled={readonly}
            />
            <label className="product-item__star" htmlFor={`${productCode}_star${stars}`} />
        </>
    );
}
