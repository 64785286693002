import { useCallback, useContext, useRef, useState } from 'react';
import { useDebounceCallback } from 'usehooks-ts';
import { commentProduct, UserData } from '../api/product';
import ErrorBox, { ErrorBoxType } from '../generic/ErrorBox';
import SpinnerContainer from '../generic/SpinnerContainer';
import { TrackingContext } from '../generic/TrackingContext';
import eventTargetValue from '../utils/eventTargetValue';
import useBooleanState from '../utils/useBooleanState';
import useStateWithSideEffect from '../utils/useStateWithSideEffect';

interface Props {
    productCode: string;
    comment: string | undefined;
    onChange(userData: UserData): void;
}

export default function ReviewComment({ productCode, comment: initialComment, onChange }: Props) {
    const [isLoading, setLoading] = useBooleanState();
    const [error, setError] = useState<ErrorBoxType | null>(null);

    const { item_list_id, item_list_name } = useContext(TrackingContext);
    const hasTrackedComment = useRef(false);
    const trackComment = useCallback(() => {
        if (!hasTrackedComment.current) {
            hasTrackedComment.current = true;
            gtag('event', 'rating', {
                comment: true,
                item_id: productCode,
                item_list_id,
                item_list_name,
            });
        }
    }, [productCode, item_list_id, item_list_name]);

    const saveComment = useDebounceCallback(
        useCallback(
            (comment: string) => {
                setLoading.toTrue();
                setError(null);
                trackComment();
                commentProduct(productCode, { comment }).then(onChange).catch(setError).finally(setLoading.toFalse);
            },
            [productCode, trackComment],
        ),
        500,
    );

    const [comment, setComment] = useStateWithSideEffect(initialComment ?? '', saveComment);

    return (
        <SpinnerContainer isSpinning={isLoading}>
            <textarea
                aria-label="Skriv et notat"
                className="product-review__comment form-control"
                rows={5}
                id={`${productCode}_comment`}
                name="comment"
                placeholder="Skriv et notat"
                value={comment}
                onChange={eventTargetValue(setComment)}
            />
            <ErrorBox errors={error} />
        </SpinnerContainer>
    );
}
