import { useOnMount } from '../utils/useComponentDidMount';
import CookieConsentModal from './CookieConsentModal';

export default function CookieConsentContainer() {
    useOnMount(() => {
        if (!document.cookie.includes('vmp-consent=') && location.pathname !== '/informasjonskapsler') {
            CookieConsentModal.call();
        }
    });

    return <CookieConsentModal.Root />;
}
