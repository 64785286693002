import { useEffect } from 'react';

export default function ScrollTracker() {
    useEffect(() => {
        // This map contains all the observed elements in dom order
        const elements = new Map<Element, boolean>();

        // This is the list of thresholds to report
        // The elements from this array will be consumed
        let thresholds = [25, 50, 75, 90];

        // eslint-disable-next-line compat/compat
        const observer = new IntersectionObserver((entries) => {
            // get all visible elements and mark them as seen
            entries.filter((e) => e.isIntersecting).forEach((entry) => elements.set(entry.target, true));

            const seenElements = elements.values().toArray();
            const currentPercentageRead = Math.round((seenElements.lastIndexOf(true) / (seenElements.length - 1)) * 100);

            thresholds = thresholds.filter((threshold) => {
                if (threshold <= currentPercentageRead) {
                    gtag('event', 'scroll', {
                        percent_scrolled: threshold,
                    });

                    // Remove from list
                    return false;
                }

                // Keep in list until later
                return true;
            });
        });

        document.querySelectorAll('article section > *').forEach((element) => {
            observer.observe(element);
            elements.set(element, false);
        });
    }, []);

    return null;
}
