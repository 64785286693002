import * as api from '.';
import { productAPI } from '../config';
import { Product } from '../model/types';
import { Availability } from '../product/ProductAvailability';
import { DetailedProduct } from '../product/productdetails/ProductDetailsPage';

export async function getProduct(productId: string) {
    return await api.get<Product>(productAPI.productURL(productId), { fields: 'DEFAULT' });
}

export async function getDetailedProduct(productId: string) {
    return await api.get<DetailedProduct>(productAPI.productURL(productId), { fields: 'FULL' });
}

export async function getProductImages(productId: string) {
    const { images } = await api.get<{ images: { format: string; url: string }[] }>(productAPI.productURL(productId), { fields: 'BASIC' });
    return images;
}

export interface UserData {
    favourite: boolean;
    inUserDefinedList: boolean;
    productCode: string;
    statusNotificationRegistered: boolean;
    review?: {
        comment: string;
        rating: number;
    };
}

export async function getProductUserData(productId: string) {
    return await api.get<UserData>(productAPI.userData(productId));
}

export async function rateProduct(productId: string, payload: { rating: number }) {
    return await api.patchJson<UserData>(productAPI.ratingUrl(productId), payload);
}

export async function commentProduct(productId: string, payload: { comment: string }) {
    return await api.patchJson<UserData>(productAPI.commentUrl(productId), payload);
}

export async function addEmailReminder(productCode: string) {
    return await api.post<UserData>(productAPI.notificationUrl(productCode));
}

export async function removeEmailReminder(productCode: string) {
    return await api.delete<UserData>(productAPI.notificationUrl(productCode));
}

export async function getUserProductAvailability(productCode: string) {
    return await api.get<Availability>(productAPI.userAvailability(productCode));
}
