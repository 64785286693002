import { useOnMount } from '../utils/useComponentDidMount';
import ConfirmEmailModal from './ConfirmEmailModal';

export default function ConfirmEmailContainer() {
    const ignoredUrls: string[] = ['/emailverification/verificationrequired', '/emailverification/verify'];
    useOnMount(({ isLoggedIn, user }) => {
        if (isLoggedIn && user?.requiresEmailVerification && !ignoredUrls.includes(location.pathname)) {
            ConfirmEmailModal.call();
        }
    });

    return <ConfirmEmailModal.Root />;
}
