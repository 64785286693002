import js from 'dedent';
import { tokenKey } from '../utils/authToken';

const ANALYTICS_ID_PROD = 'G-DEYJBVMZRC';
const ANALYTICS_ID_DEV = 'G-G5R4J3MCMY';

export interface Props {
    config?: object;
}

export default function GA4({ config = {} }: Props) {
    return (
        <>
            <script
                type="module"
                dangerouslySetInnerHTML={{
                    __html: js`
                        window.dataLayer = window.dataLayer || [];
                        window.gtag = function(){
                            window.dataLayer.push(arguments);
                        };
                        window.loadGoogleAnalytics = function(dataLayer){
                            // clear the dataLayer if this method is called from the dialog
                            window.dataLayer = dataLayer || window.dataLayer;
                            const ANALYTICS_ID = location.host === 'www.vinmonopolet.no' ? ${JSON.stringify(ANALYTICS_ID_PROD)} : ${JSON.stringify(ANALYTICS_ID_DEV)};
                            gtag("js", new Date());
                            gtag("config", ANALYTICS_ID, ${JSON.stringify({ ...config, currency: 'NOK' })});
                            const scriptElm = document.createElement('script');
                            scriptElm.src = 'https://www.googletagmanager.com/gtag/js?id='+ANALYTICS_ID;
                            document.head.append(scriptElm);
                            const user = JSON.parse(localStorage.getItem(${JSON.stringify(tokenKey)}))?.user;
                            if (user) {
                                gtag("config", ANALYTICS_ID, {
                                    user_id: user.trackingId,
                                    user_properties: {
                                        store: user.favouriteStore?.displayName,
                                        postcode: user.defaultAddress?.postalCode
                                    }
                                });
                            }

                            addEventListener("error", function(event){
                                gtag("event", "exception", {
                                    description: event.message,
                                    stack: event.error instanceof Error ? event.error.stack : event.message,
                                });
                            });
                        }

                        if (document.cookie.includes('vmp-consent=true')) {
                            loadGoogleAnalytics();
                        }
                    `,
                }}
            ></script>
        </>
    );
}

/**
 * Track all button and link clicks inside elements with the data-track-link-click attribute
 * This is primarily used to track links on frontpage and category page
 */
export function trackLinkClicks() {
    document.addEventListener(
        'click',
        ({ target }) => {
            if (target instanceof HTMLElement) {
                const buttonOrLink = target.closest('a, button');
                const module = buttonOrLink?.closest<HTMLElement>('[data-track-link-click]')?.dataset.trackLinkClick;
                if (buttonOrLink && module) {
                    gtag('event', 'link_click', {
                        link_text: buttonOrLink.textContent,
                        link_url: buttonOrLink instanceof HTMLAnchorElement ? buttonOrLink.href : undefined,
                        module,
                    });
                }
            }
        },
        { capture: true },
    );
}
