import dedent from 'dedent';
import Footer from './footer/Footer';
import Header from './header/Header';
import Callables from './layout/Callables';
import ScrollTracker from './layout/ScrollTracker';
import SkipLinks from './layout/SkipLinks';
import TableOfContents from './sanity/components/TableOfContents';
import { DraftModeLoader } from './sanity/infrastructure/detectSanityPresentationMode';
import { DefinitionPopup } from './sanity/richTextComponents/DefinitionMark';
import { GrapePopup } from './sanity/richTextComponents/GrapeMark';

export type Components = Record<string, React.ComponentType<any>>;

const clientComponents: Components = {
    Header,
    Footer,
    SkipLinks,
    TableOfContents,
    GrapePopup,
    DefinitionPopup,
    DraftModeLoader,
    Callables,
    ScrollTracker,
    // Add new client components here
};

export function getComponent(name: string) {
    if (name in clientComponents) return clientComponents[name];

    throw new Error(dedent`
        Could not find component '${name}' in 'clientComponents', did you forget to add it?
        It is defined as
        \`\`\`
        const clientComponents: Components = {
            ${Object.keys(clientComponents).join(',\n            ')}
        };
        \`\`\`
    `);
}

/**
 * This is called by the page chunks, adding their own page specific chunk to the clientComponents
 * so that the DOMContentLoaded event listener below can use it
 */
export function defineClientComponent(name: string, component: React.ComponentType) {
    clientComponents[name] = component;
}
