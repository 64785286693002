import { createCallable } from 'react-call';
import { UserData } from '../api/product';
import Modal from '../generic/Modal';
import RatingAndComment from '../product/RatingAndComment';

interface Props {
    productCode: string;
    rating: number | undefined;
    comment: string;
    onUserDataChange(userData: UserData): void;
}

const ProductReviewModal = createCallable<Props>(({ call, rating, comment, productCode, onUserDataChange }) => (
    <Modal isOpen={true} padding size="small" onClose={call.end}>
        <h3>Min vurdering</h3>

        <RatingAndComment productId={productCode} review={{ rating, comment }} onUserDataChange={onUserDataChange} />
    </Modal>
));

export default ProductReviewModal;
