import { EventHandler, SyntheticEvent } from 'react';

export default function stopPropagation<T extends Element, E extends Event>(
    ...handlers: (undefined | EventHandler<SyntheticEvent<T, E>>)[]
): EventHandler<SyntheticEvent<T, E>> {
    return (event: SyntheticEvent<T, E>) => {
        event?.stopPropagation();
        handlers.filter(Boolean).map((h) => h(event));
    };
}
