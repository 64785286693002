import { JSXElementConstructor, PropsWithChildren, ReactElement, createContext, createElement, useContext } from 'react';
import { getComponent } from '../clientComponents';

const isServer = typeof window === 'undefined';

const ClientContext = createContext(false);

export function ClientRendering({ children, props }: PropsWithChildren<{ props?: any }>) {
    try {
        return (
            <ClientContext.Provider value={true}>
                {props && <script type="application/json" dangerouslySetInnerHTML={{ __html: JSON.stringify(props, replacer) }}></script>}
                {children}
            </ClientContext.Provider>
        );
    } catch (e: any) {
        return (
            <span style={{ background: 'pink', color: 'darkred' }}>
                Oops<pre style={{ display: 'none' }}>{e}</pre>
            </span>
        );
    }
}

export default function ClientReactRoot<As extends keyof JSX.IntrinsicElements = 'div'>({
    as,
    children,
    ...props
}: { as?: As; children: ReactElement<any, JSXElementConstructor<any>> } & JSX.IntrinsicElements[As]) {
    const alreadyClient = useContext(ClientContext);
    if (isServer && !alreadyClient) {
        return createElement(
            as ?? 'div',
            {
                ...props,
                'data-react-component': assertComponentDefined(children.type.name),
            },
            createElement(
                ClientRendering,
                {
                    props: children.props,
                },
                children,
            ),
        );
    } else {
        return createElement(as ?? 'div', props, createElement(ClientRendering, {}, children));
    }
}

function assertComponentDefined(name: string) {
    getComponent(name);
    return name;
}

/**
 * Serialize basic react components so they can be revived in the frontend
 */
function replacer(key: any, value: any) {
    if (value && typeof value === 'object' && '$$typeof' in value && typeof value.$$typeof === 'symbol' && typeof value.type === 'function') {
        return value.type(value.props);
    }
    return value;
}

const reactElementProps = ['type', 'key', 'ref', 'props', '_owner', '_store'];

/**
 * Revive basic react elements from the backend. This only supports basic html, not functions
 */
export function reviver(key: string, value: any) {
    if (value && typeof value === 'object' && reactElementProps.every((key) => key in value) && typeof value.type === 'string') {
        return createElement(value.type, { ...value.props, key: value.key });
    }
    return value;
}
