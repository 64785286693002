import * as style from './Definition.module.css';

interface DefinitionProps {
    name?: string;
    summary?: string;
}

export default function Definition({ name, summary }: DefinitionProps) {
    return (
        <>
            <span className={`${style.typeName}`}>Definisjon</span>
            <h2>{name}</h2>
            {summary?.split('\n').map((t, i) => <p key={i}>{t}</p>)}
        </>
    );
}
