import { UserData } from '../api/product';
import ReviewComment from '../product-tools/ReviewComment';
import ReviewStars from './ReviewStars';

export interface Props {
    productId: string;
    review:
        | {
              rating?: number;
              comment?: string;
          }
        | undefined;
    onUserDataChange(userData: UserData): void;
}

export default function RatingAndComment({ productId, review, onUserDataChange }: Props) {
    return (
        <form className="rating-and-comments">
            <ReviewStars productCode={productId} rating={review?.rating} onChange={onUserDataChange} />
            <ReviewComment productCode={productId} comment={review?.comment} onChange={onUserDataChange} />
        </form>
    );
}
