function openTargetedDetailsElement() {
    const target = location.hash;
    const element = target && document.querySelector(target)?.closest('details');
    if (element instanceof HTMLDetailsElement) element.open = true;
}

export default function polyfillTargetInsideDetailsElement() {
    openTargetedDetailsElement();
    window.addEventListener('hashchange', openTargetedDetailsElement);
}
