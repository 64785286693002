export function userURL() {
    return '/vmpws/v3/vmp/user';
}

export function currentUserURL() {
    return '/vmpws/v3/vmp/user';
}

export function updateEmailURL() {
    return '/vmpws/v3/vmp/user/email';
}

export function updatePasswordURL() {
    return '/vmpws/v3/vmp/user/password';
}

export function favouriteStoreURL() {
    return '/vmpws/v3/vmp/user/favouritestore';
}

export function addressesURL() {
    return '/vmpws/v3/vmp/user/addresses';
}

export function addressURL(id: string) {
    return `/vmpws/v3/vmp/user/addresses/${id}`;
}

export function paymentDetailsURL() {
    return '/vmpws/v3/vmp/user/paymentdetails';
}

export function paymentDetailDefaultURL() {
    return '/vmpws/v3/vmp/user/paymentdetails/default';
}

export function paymentDetailURL(id: string) {
    return `/vmpws/v3/vmp/user/paymentdetails/${id}`;
}

export function verifyEmailURL() {
    return '/vmpws/v2/vmp/verifyemail';
}

export function resendVerificationEmailTokenExpiredURL() {
    return '/vmpws/v2/vmp/resendverificationemailforexpiredtoken';
}

export function resendVerificationEmailURL() {
    return '/vmpws/v3/vmp/user/resendverificationemail';
}

export function magazineSubscriptionURL() {
    return '/vmpws/v3/vmp/user/magazine';
}

export function consentURL(versionId?: string) {
    const versionPart = versionId ? `/${versionId}` : '';
    return `/vmpws/v2/vmp/users/current/consents/CONSENT_REGISTRATION_VMP${versionPart}`;
}

export function importedAccountURL() {
    return '/vmpws/v2/vmp/importedaccount';
}

export function importedAccountTokenURL() {
    return '/vmpws/v2/vmp/importedaccount/checkToken';
}

export function orderDetailsURL(orderCode: string) {
    return `/vmpws/v3/vmp/users/current/orders/${orderCode}`;
}

export function orderHistoryURL() {
    return '/vmpws/v2/vmp/users/current/orders';
}

export function customerInformationLookup() {
    return '/vmpws/v2/vmp/customer-lookup';
}

export function productNotificationURL() {
    return '/vmpws/v2/vmp/users/current/notifications';
}
