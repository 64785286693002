import { Fragment, MouseEvent, useEffect, useState } from 'react';
import { useToggle } from 'usehooks-ts';
import { FooterLinks, FooterResponse } from '../../pages/api/types';
import { getAnonymously } from '../api';
import CookieConsentModal from '../cookieConsentModal/CookieConsentModal';
import Icon from '../generic/Icon';
import salesforceChat from '../layout/salesforceChat';
import { useOnMount } from '../utils/useComponentDidMount';

const initialFooterData: FooterResponse = {
    footerLinks: [],
    address: '',
    contact: {
        phone: '',
        email: '',
    },
    facebook: '',
    linkedIn: '',
    organizationNumber: '',
    title: '',
    openingHours: {
        title: '',
        openingHours: [],
    } as any,
    linkToEnglishPage: undefined,
};

export default function Footer({ data: initialData }: { data?: FooterResponse }) {
    const [data, setData] = useState<FooterResponse>(initialData ?? initialFooterData);

    useOnMount(async ({ user, isLoggedIn }) => {
        salesforceChat(isLoggedIn, user);
    });

    useEffect(() => {
        if (initialData) return;
        getAnonymously<FooterResponse>('/api/footer').then(setData);
    }, [initialData]);

    return (
        <>
            <div className="section-footer-links" onClick={trackLinkClick}>
                <div className="section__inner-container">
                    <div className="footer-links">{data.footerLinks?.map((p) => <FooterGroup key={p._key} {...p} />)}</div>
                </div>
            </div>
            <div className="section-footer-contact" onClick={trackLinkClick}>
                <div className="section__inner-container">
                    <div className="footer-contact">
                        <div className="footer__section">
                            <h2 className="footer__headline">{data.title}</h2>
                            <div className="footer-items">
                                <div className="footer-opening-hours">
                                    <b>{data.openingHours?.title}</b>
                                    {data.openingHours?.openingHours
                                        ?.filter((p) => !p.isClosed)
                                        .map((p) => (
                                            <Fragment key={p._key}>
                                                <span>{p.days}</span>
                                                <span>
                                                    {p.open} - {p.close}
                                                </span>
                                            </Fragment>
                                        ))}
                                </div>
                                <ul id="ContactUsNavNode" className="list-unstyled">
                                    <li className="CallUsLink">
                                        <a href={`tel:${data.contact?.phone?.replaceAll(/\s/g, '')}`} className="footer-icon-button">
                                            {data.contact?.phone}
                                        </a>
                                    </li>
                                    <li className="SendUsEmailLink">
                                        <a href={`mailto:${data.contact?.email}`} className="footer-icon-button">
                                            {data.contact?.email}
                                        </a>
                                    </li>
                                </ul>
                                <ul id="footer-contact-links" className="list-unstyled">
                                    <li className="VmpOnFacebookLink">
                                        <a href={data.facebook} className="footer-icon-link">
                                            Facebook
                                        </a>
                                    </li>
                                    <li className="VmpOnLinkedInLink">
                                        <a href={data.linkedIn} className="footer-icon-link">
                                            LinkedIn
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="extended-footer">
                        <div className="extended-footer-title">
                            <p>
                                {data.address}
                                <br />
                                Organisasjonsnummer: {data.organizationNumber}
                                <br />
                                Telefonnummer: {data.contact?.phone}
                            </p>
                        </div>
                        <ul className="list-unstyled">
                            <li>
                                <a href={data.linkToEnglishPage?.link?.internalReference.path} className="footer-button">
                                    {data.linkToEnglishPage?.name}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

function FooterGroup({ _key, title, links }: FooterLinks) {
    const [expanded, toggle] = useToggle();

    return (
        <div className="navigation-toggler-container">
            <h2 className="navigation-toggler navigation-toggler-title">{title}</h2>
            <button className="btn navigation-toggler navigation-toggler-btn" onClick={toggle} aria-expanded={expanded} aria-controls={_key}>
                {title}
            </button>
            <ul id={_key} className="navigation-content-footer" aria-hidden={!expanded}>
                {links.map((p) => {
                    switch (p._type) {
                        case 'cookie-administration':
                            return (
                                <li key={p._key}>
                                    <a className="link--no-border" onClick={() => CookieConsentModal.call()}>
                                        {p.title}
                                    </a>
                                </li>
                            );
                        case 'namedInternalLink':
                            return (
                                <li key={p._key}>
                                    <a href={p.link?.internalReference?.path} className="link--no-border">
                                        {p.name || p.link?.internalReference?.title}
                                    </a>
                                </li>
                            );
                        case 'namedExternalLink':
                            return (
                                <li key={p._key}>
                                    <a
                                        href={p.link?.href}
                                        target={p.link?.blank ? '_blank' : undefined}
                                        rel={p.link?.blank ? 'noreferrer' : undefined}
                                        className="link--no-border"
                                    >
                                        {p.name}
                                        <Icon className="icon-external-link" renderWithSpan />
                                    </a>
                                </li>
                            );
                        case 'namedRelativeLink':
                            return (
                                <li key={p._key}>
                                    <a href={p.url} className="link--no-border">
                                        {p.name}
                                    </a>
                                </li>
                            );
                    }
                })}
            </ul>
        </div>
    );
}

function trackLinkClick(event: MouseEvent): void {
    if (event.target instanceof HTMLAnchorElement) {
        gtag('event', 'footer_link_click', {
            link_text: event.target.textContent,
            link_url: event.target.href,
        });
    }
}
