export function getCookieByPartialName(name: string): string | undefined {
    if (typeof document === 'undefined') return '';
    return document.cookie
        ?.split(';')
        .map((c) => c.split('=')[0])
        .find((cookie) => cookie.includes(name))
        ?.trim();
}

/**
 * Delete the cookie if it exists
 *
 * @param cookieName name of Cookie
 * @returns `true` if the cookie was deleted, otherwise `false`
 */
export function deleteCookie(cookieName: string) {
    if (!document.cookie.includes(`${cookieName}=`)) {
        // If the cookie doesn't exist then return false, as nothing was deleted
        return false;
    }

    // The cookie might have been set on a base domain, so we need to try upwards until it is gone
    const parts = document.location.hostname.split('.');
    while (parts.length > 0 && document.cookie.includes(`${cookieName}=`)) {
        const cookie = `${cookieName}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT; Domain=${parts.join('.')}`;
        console.log('delete', cookie);
        document.cookie = cookie;
        parts.shift();
    }

    return true;
}

/**
 * Delete all tracking cookies
 *
 * @returns `true` if any cookie was deleted, otherwise `false`
 */
export function deleteTrackingCookies() {
    let deleted = false;
    const gaCookieName = getCookieByPartialName('_ga_');
    if (gaCookieName) {
        deleted ||= deleteCookie(gaCookieName);
    }
    deleted ||= deleteCookie('_ga');
    deleted ||= deleteCookie('skyra.state');
    return deleted;
}

export function setConsentCookie(consented: boolean) {
    const futureDate = new Date().getTime() + 90 * 24 * 60 * 60 * 1000;
    document.cookie = `vmp-consent=${consented}; Path=/; Expires=${new Date(futureDate)}`;
}
