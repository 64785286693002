// extracted by mini-css-extract-plugin
var _1 = "dialog-MqTnNSaV";
var _2 = "dialog-close-K53pM06o";
var _3 = "dialog-content-Wi0UCZzC";
var _4 = "dialog-close-K53pM06o";
var _5 = "dialog-content-Wi0UCZzC";
var _6 = "large-g08s2NPR";
var _7 = "medium-qZiA1gkc";
var _8 = "no-padding-KZVZCNws";
var _9 = "no-padding-KZVZCNws";
var _a = "small-SstETDah";
var _b = "white-Gb55tFsj";
export { _1 as "dialog", _2 as "dialog-close", _3 as "dialog-content", _4 as "dialogClose", _5 as "dialogContent", _6 as "large", _7 as "medium", _8 as "no-padding", _9 as "noPadding", _a as "small", _b as "white" }
