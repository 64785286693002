import { MouseEventHandler, useEffect, useState } from 'react';
import * as style from './AppStore.module.css';

export function AppStore() {
    const [{ link, text }, setState] = useState({ link: '', text: '' });

    useEffect(() => {
        if (localStorage.getItem('vmpapp')) return;

        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            const link = 'https://play.google.com/store/apps/details?id=no.vinmonopolet.android';
            const text = 'Last ned Vinmonopolets app fra Google Play';
            setState({ link, text });
            gtag('event', 'banner_view', {
                banner: 'app_download',
            });
        }

        // safari på ios håndteres via meta-tag apple-itunes-app,
        // her håndteres chrome og firefox
        if (/iphone/i.test(userAgent) && (/CriOS/i.test(userAgent) || /fxiOS/i.test(userAgent))) {
            const link = 'https://itunes.apple.com/no/app/vinmonopolet/id1080930756?l=no';
            const text = 'Last ned Vinmonopolets app fra App Store';
            setState({ link, text });
            gtag('event', 'banner_view', {
                banner: 'app_download',
            });
        }
    }, []);

    const dismiss: MouseEventHandler<HTMLElement> = (e) => {
        localStorage.setItem('vmpapp', `dismissed ${new Date()}`);
        setState({ link: '', text: '' });
        gtag('event', 'banner_view', {
            banner: 'app_download',
            link_text: e.currentTarget.textContent || e.currentTarget.ariaLabel,
        });
    };

    if (!link) return <></>;

    return (
        <div className={style.container}>
            <a href={link} onClick={dismiss}>
                {text}
            </a>
            <button onClick={dismiss} aria-label="Lukk"></button>
        </div>
    );
}
