import cn from 'classnames';
import { UIEvent, useEffect, useRef, useState } from 'react';
import type { Divider, RelativeLink } from '../../pages/api/types';
import { onElementBlur } from '../utils/onElementBlur';
import preventDefault from '../utils/preventDefault';
import { trackHeaderLinkClick } from '../utils/trackLinkClick';
import useBooleanState from '../utils/useBooleanState';

export default function HeaderCategoryBar({ links }: { links: (RelativeLink | Divider)[] }) {
    const box = useRef<HTMLUListElement>(null);
    const [maxHeight, setMaxHeight] = useState(0);
    const [open, setOpen] = useBooleanState();

    useEffect(() => {
        if (!open) return;

        function handler() {
            if (!box.current) return;

            const { top } = box.current?.getBoundingClientRect();
            setMaxHeight(window.innerHeight - top);
        }

        handler();

        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, [open]);

    const hidden = useScrollHiding() && !open;

    return (
        <div className={cn('header-category-bar', { 'scroll-hidden': hidden })}>
            <div className="section__inner-container">
                <nav className="primary-navigation" id="primary-navigation" tabIndex={-1}>
                    <ul>
                        {/*######### Fremhevede varegrupper #########*/}
                        {links
                            .filter((t) => t._type === 'namedRelativeLink')
                            .slice(0, 10)
                            .map(({ _key, url, name }) => (
                                <li key={_key} className="header-category-bar-item">
                                    <a href={url} rel="nofollow" onClick={trackHeaderLinkClick}>
                                        {name}
                                    </a>
                                </li>
                            ))}
                        {/*######### Varegruppe popup - de som ikke er fremhevet #########*/}
                        <li className="see-more" onBlur={onElementBlur(setOpen.toFalse)}>
                            <a
                                className="navigation-toggler"
                                onClick={preventDefault(setOpen.toggle, trackHeaderLinkClick)}
                                aria-expanded={open}
                                aria-controls="header-category-bar-see-more"
                                href="#"
                            >
                                Se flere
                            </a>
                            <ul id="header-category-bar-see-more" onScroll={preventPageScroll} style={{ maxHeight }} ref={box}>
                                {links.map((p) =>
                                    p._type === 'divider' ? (
                                        <li key={p._key} className="header-category-bar-see-more-item divider" />
                                    ) : (
                                        <li key={p._key} className="header-category-bar-see-more-item">
                                            <a href={p.url} rel="nofollow" onClick={trackHeaderLinkClick}>
                                                {p.name}
                                            </a>
                                        </li>
                                    ),
                                )}
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

function useScrollHiding() {
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        let position = window.scrollY;

        const handler = () => {
            const scroll = window.scrollY;

            if (scroll < position) {
                setHidden(false);
            } else if (scroll >= 200) {
                setHidden(true);
            }
            position = scroll;
        };

        document.addEventListener('scroll', handler, { passive: true });

        return () => document.removeEventListener('scroll', handler);
    }, []);

    return hidden;
}

function preventPageScroll(e: UIEvent) {
    if (e.currentTarget.scrollTop < 1) {
        e.currentTarget.scrollTop = 1;
    }
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight < 1) {
        e.currentTarget.scrollTop = e.currentTarget.scrollTop - 1;
    }
}
