import cn from 'classnames';
import { PropsWithChildren, useEffect } from 'react';
import { useScrollLock } from 'usehooks-ts';
import preventDefault from '../utils/preventDefault';
import Icon from './Icon';
import * as style from './Modal.module.css';

export interface Props {
    isOpen?: boolean;
    onClose?: () => void;
    padding?: boolean;
    size?: 'small' | 'medium' | 'large';
}

export default function Modal({ isOpen = false, size = 'medium', padding = false, onClose, children }: PropsWithChildren<Props>) {
    if (!isOpen) return;

    useEffect(() => {
        if (isOpen && !onClose) {
            const preventESC = (event: KeyboardEvent) => {
                if (event.key === 'Escape' && isOpen) {
                    event.preventDefault();
                }
            };

            document.addEventListener('keydown', preventESC);

            return () => {
                document.removeEventListener('keydown', preventESC);
            };
        }
    }, [isOpen, onClose]);

    return (
        <dialog
            ref={(e) => e?.showModal()}
            className={cn(style.dialog, style[size])}
            onCancel={preventDefault(onClose)}
            onClick={(e) => e.target === e.currentTarget && onClose?.()}
        >
            <ScrollLock />
            <div className={cn(style.dialogContent, { [style.noPadding]: !padding })}>
                {!!onClose && (
                    <button className={style.dialogClose} onClick={onClose} aria-label="Lukk">
                        <Icon className="icon-close" />
                    </button>
                )}
                {children}
            </div>
        </dialog>
    );
}

function ScrollLock() {
    useScrollLock();

    return null;
}
