import Toast from './Toast';
import * as style from './ToastContainer.module.css';

export default function ToastContainer() {
    return (
        <div className={style.toastContainer}>
            <Toast.Root />
        </div>
    );
}
