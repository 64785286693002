export function productURL(productCode: string) {
    return `/vmpws/v3/vmp/products/${productCode}`;
}

export function stockURL(productCode: string) {
    return `/vmpws/v2/vmp/products/${productCode}/stock`;
}

export function ratingUrl(productCode: string) {
    return `/vmpws/v3/vmp/products/${productCode}/rating`;
}

export function commentUrl(productCode: string) {
    return `/vmpws/v3/vmp/products/${productCode}/comment`;
}

export function notificationUrl(productCode: string) {
    return `/vmpws/v3/vmp/products/${productCode}/notification`;
}

export function similarProductsUrl(productCode: string) {
    return `/vmpws/v2/vmp/products/${productCode}/similar`;
}

export function userData(productCode: string) {
    return `/vmpws/v2/vmp/products/${productCode}/userdata`;
}

export function userAvailability(productCode: string) {
    return `/vmpws/v3/vmp/products/${productCode}/availability`;
}
