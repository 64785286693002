import { onElementBlur } from '../utils/onElementBlur';
import useBooleanState from '../utils/useBooleanState';
import { Favorites } from './Favorites';
import Logout from './Logout';
import MyPageLink from './MyPageLink';
import PrimaryNavigationTop, { PrimaryNavigationTopProps } from './PrimaryNavigationTop';

export default function HeaderMobileMenu({ links }: PrimaryNavigationTopProps) {
    const [isOpen, setIsOpen] = useBooleanState();

    return (
        <div className="hamburger-menu" onBlur={onElementBlur(setIsOpen.toFalse)}>
            <button
                onClick={setIsOpen.toggle}
                aria-controls="header-mobile-menu"
                aria-expanded={isOpen}
                aria-label={isOpen ? 'Steng menyen' : 'Åpne menyen'}
            >
                <span className="hamburger-menu__icon" aria-hidden="true">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </button>
            <div className="header-mobile-menu" id="header-mobile-menu">
                <div className="secondary-navigation-items-mobile">
                    <MyPageLink />
                    <Favorites />
                </div>
                <PrimaryNavigationTop links={links} />
                <Logout />
            </div>
        </div>
    );
}
