import { useEffect, useState } from 'react';
import type { HeaderResponse } from '../../pages/api/types';
import { getAnonymously } from '../api';
import { AppStore } from './AppStore';
import { Favorites } from './Favorites';
import HeaderCategoryBar from './HeaderCategoryBar';
import HeaderMobileMenu from './HeaderMobileMenu';
import MiniCart from './MiniCart';
import MyPageLink from './MyPageLink';
import PrimaryNavigationTop from './PrimaryNavigationTop';
import SearchBox from './SearchBox';

const initialHeaderData: HeaderResponse = {
    primaryNavigation: [
        { _key: '1', _type: 'namedRelativeLink', name: '', url: '' },
        { _key: '2', _type: 'namedRelativeLink', name: '', url: '' },
        { _key: '3', _type: 'namedRelativeLink', name: '', url: '' },
        { _key: '4', _type: 'namedRelativeLink', name: '', url: '' },
    ],
    headerCategories: [{ _key: '1', _type: 'namedRelativeLink', name: '', url: '' }],
};

export default function Header({ data: initialData }: { data?: HeaderResponse }) {
    const [data, setData] = useState(initialData ?? initialHeaderData);

    useEffect(() => {
        if (initialData) return;
        getAnonymously<HeaderResponse>('/api/header').then(setData);
    }, [initialData]);

    return (
        <>
            <AppStore />
            <div className="section__logo-and-nav-toggler">
                <div className="section__inner-container">
                    <div className="site__logo-and-nav-toggler">
                        <a className="site__go-home" href="/" aria-label="Tilbake til forsiden" />

                        <div className="site__search-container">
                            <SearchBox />
                        </div>

                        <div className="primary-navigation-desktop">
                            <div className="primary-navigation-items-desktop">
                                <PrimaryNavigationTop links={data.primaryNavigation} />
                            </div>
                        </div>

                        <div className="secondary-navigation">
                            <MyPageLink />
                            <Favorites />
                            <MiniCart />
                            <HeaderMobileMenu links={data.primaryNavigation} />
                        </div>
                    </div>
                </div>
            </div>
            <HeaderCategoryBar links={data.headerCategories} />
        </>
    );
}
